<template>
	<div class="wrap">
		<div class="tit">{{info.title}}</div>
		<div class="content">
			<ul class="list">
				<li v-for="(item,index) in info.wImg" :key="index">
					<el-image :src="item" />
				</li>
			</ul>
			<div class="info">
				<p>主题名称&nbsp;&nbsp;{{info.hangye}}</p>
				<!-- <p>代号&nbsp;&nbsp;{{info.viewnum}}</p> -->
				<p>设备&nbsp;&nbsp;桌面版</p>
				<p>适用行业&nbsp;&nbsp;互联网</p>
				<p>价格<span>￥{{info.money}}</span></p>
				<p class="brief">{{info.content}}</p>
				<div class="info-btn">
					<span @click="demoUrl">演示地址</span>
					<span @click="handleClickBuy" v-if="info.order==0">立即购买</span> <!-- 0是未购买-->
					<span @click="handleClickSet" v-else>去使用</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				info: {},
				url: "",
				jumpUrl:'',
				zhaungxiuA:'',
			}
		},
		created() {
			this.$axios.post('/getOneTemplateInfo', {
				id: this.$route.params.id,
			}).then(data => {
				this.jumpUrl = data.tplname
				this.info = data
				this.url = data.url
			})
		},
		methods: {
			handleClickBuy() {
				this.$axios.post('/buyTemplate', {
					seller_id: this.$store.state.SELLER_ID,
					tplname: this.info.tplname,
				}).then(data => {
					if (data.code == 400005) {
						this.$message.error('购买失败')
						return
					}
					this.info.order = 1
					this.$message.success('购买成功')
				})
			},
			handleClickSet() {
				let  urlJump = this.jumpUrl
				 open(this.$store.state.BASE_URL + '/zhuangxiu')
			},
			demoUrl(e) {
				const tUrl = this.url;
				window.open(tUrl, '_blank')
			}
		}
	}
</script>

<style scoped>
	.wrap{
		width: 1600px;
		background-color: #fff;
	}
	.tit {
		height: 96px;
		line-height: 96px;
		text-align: center;
		font-size: 24px;
		color: #333333;
		border-bottom: 1PX solid #ebebeb;
	}

	.content {
		padding: 34px 33px 59px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	.list {
		display: flex;
		flex-wrap: wrap;
		margin-left: -18px;
		width: 1248px;
		/* border: 1px solid red; */
	}

	.list li {
		width: 395px;
		height: 530px;
		border: 1px solid #ececec;
		margin-left: 18px;
		margin-bottom: 20px;
		/* border: 1px solid green; */
		overflow: hidden;
	}

	.info {
		width: 276px;
		background: #f6f6fe;
		border: 1px solid #ebebeb;
		padding: 20px 32px 20px;
		flex: none;
		font-size: 14px;
	}

	.info p {
		height: 42px;
		line-height: 42px;
	}

	.info .brief {
		line-height: 24px;
		margin-top: 23px;
	}

	.info span {
		font-size: 18px;
		font-weight: 700;
		color: #ea644a;
		margin-left: 26px;
	}

	.info-btn {
		padding: 10px 0 0;
		display: flex;
		margin: 40px;
		justify-content: center;
	}

	.info-btn span {
		color: #fff;
		width: 100px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		margin: 10px 10px;
		font-size: 14px;
		border-radius: 5px;
		cursor: pointer;
	}

	.info-btn[data-v-6f58c800] {
		padding: 0;
		margin: 25px 0px;

	}

	.info-btn span:nth-of-type(1) {
		background: #206ff0;
	}

	.info-btn span:nth-of-type(2) {
		background: #fd6c34;
	}
</style>
